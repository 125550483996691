import { NgClass } from '@angular/common';
import { Component, HostListener, OnInit, Renderer2 } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterOutlet,
} from '@angular/router';
import { ClearPartnerTypesAction } from '@app/modules/@core/store/partner-types/partner-types.actions';
import { AuthenticationService } from '@core/services/authentication.service';
import { Logger } from '@core/services/logger.service';
import { ClearKitCollectorTypesAction } from '@core/store/kits/kits.actions';
import { ClearPartnersStateAction } from '@core/store/partners/partners.actions';
import {
  ClearUserProfileAction,
  LoadUserProfileAction,
} from '@core/store/user-profile/user-profile.actions';
import { CoreUtilsService } from '@core/utils/core-utils.service';
import { NbIconLibraries, NbToastrService } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { combineLatest } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { environment } from './../environments/environment';
import { ClearRunsStateAction } from './modules/@core/store/runs/runs.actions';
import { PdfPreviewTemplateComponent } from './modules/pdf-request-form/components/pdf-preview-template/pdf-preview-template.component';
import { PdfPreviewDirective } from './modules/pdf-request-form/directives/pdf-preview.directive';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [
    NgClass,
    RouterOutlet,
    PdfPreviewDirective,
    PdfPreviewTemplateComponent,
  ],
})
export class AppComponent implements OnInit {
  public extraClasses = [];
  favIcon: HTMLLinkElement = document.querySelector('#favIcon');
  private readonly logger = new Logger('AppComponent');

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private store: Store,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private nbToast: NbToastrService,
    private iconLibraries: NbIconLibraries,
    private coreUtilsService: CoreUtilsService,
    private renderer: Renderer2
  ) {
    if (environment.stagingFavIcon) {
      this.favIcon.href = '/assets/favicon-stg.png';
    }

    combineLatest([
      this.router.events,
      toObservable(this.coreUtilsService.tabletMode),
    ]).subscribe(([event, tabletMode]) => {
      if (event instanceof NavigationEnd) {
        const data = this.mergeRouteData(this.route.snapshot);
        this.extraClasses = [];
        if (data.stickyTableHeader) {
          this.extraClasses.push('stickyTableHeader');
        }
        if (tabletMode) {
          this.extraClasses.push('tabletMode');
          this.renderer.setStyle(document.documentElement, 'font-size', '18px');
        } else {
          this.renderer.setStyle(document.documentElement, 'font-size', '16px');
        }
      }
    });

    this.iconLibraries.registerFontPack('far', {
      packClass: 'fa-regular',
      iconClassPrefix: 'fa',
    });

    this.iconLibraries.registerFontPack('fal', {
      packClass: 'fa-light',
      iconClassPrefix: 'fa',
    });
  }

  @HostListener('click', ['$event'])
  click(event: PointerEvent) {
    const toast = (event.target as HTMLInputElement).closest('nb-toast');

    // Check if parent is nb-toast
    if (toast) {
      const type = Array.from(toast.classList).find((elClass: string) =>
        elClass.includes('status-')
      );

      // Only copy danger toasts
      if (type === 'status-danger') {
        const title = toast.getElementsByClassName('title')[0]?.textContent;
        const description =
          toast.getElementsByClassName('message')[0]?.textContent;

        navigator.clipboard.writeText(
          `[Toast Error] ${title} - ${description}`
        );
        this.nbToast.info('Error copied to clipboard', 'Copied to clipboard', {
          duration: 2000,
        });
      }
    }
  }

  mergeRouteData(route) {
    let data: any = {};
    while (route) {
      data = { ...data, ...route.data };
      route = route.firstChild;
    }
    this.logger.info(data);
    return data;
  }

  ngOnInit() {
    if (environment.production) {
      Logger.enableProductionMode();
    }
    this.authService.initialize();
    this.logger.debug('init');
    this.setTranslations();
    this.authService
      .getAccessToken$()
      .pipe(distinctUntilChanged())
      .subscribe((token) => {
        this.logger.debug(`auth changed ${token !== null}`);
        this.store.dispatch(
          token
            ? new LoadUserProfileAction()
            : [
                new ClearUserProfileAction(),
                new ClearPartnersStateAction(),
                new ClearPartnerTypesAction(),
                new ClearRunsStateAction(),
                new ClearKitCollectorTypesAction(),
              ]
        );
        if (token && this.router.url.startsWith('/auth')) {
          return this.router.navigate(['/']);
        }
        if (!token && !this.router.url.startsWith('/auth')) {
          return this.router.navigate(['/auth']);
        }
      });
  }

  private setTranslations() {
    this.logger.debug('setTranslations');
    this.translate.setDefaultLang('en');
    this.translate.use('en');
  }
}
