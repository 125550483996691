import { enableProdMode } from '@angular/core';

import { environment } from './environments/environment';

import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from '@app/app.config';
import * as Sentry from '@sentry/angular';
import { AppComponent } from './app/app.component';

if (environment.production) {
  enableProdMode();
}

Sentry.init({
  dsn: environment.sentry.dsn,
  environment: environment.sentry.environment,
  normalizeDepth: 5,
  beforeSend: (event) => {
    if (
      !environment.sentry.enabled ||
      window.location.href.includes('localhost')
    ) {
      // Console error message when developing
      console.error(event.message);
      return null;
    }
    return event;
  },
});

bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error(err)
);
