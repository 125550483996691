import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PdfPreviewTemplateStateService } from '@app/modules/pdf-request-form/services/pdf-preview-template-state.service';
import { PdfPreviewTemplateService } from '@app/modules/pdf-request-form/services/pdf-preview-template.service';
import { PdfPreviewTemplateComponent } from './components/pdf-preview-template/pdf-preview-template.component';
import { PdfPreviewDirective } from './directives/pdf-preview.directive';

@NgModule({
  imports: [CommonModule, PdfPreviewTemplateComponent, PdfPreviewDirective],
  providers: [PdfPreviewTemplateStateService, PdfPreviewTemplateService],
  exports: [PdfPreviewTemplateComponent, PdfPreviewDirective],
})
export class PdfRequestFormModule {}
