import { Routes } from '@angular/router';
import { AuthGuardService } from '@core/services/auth-guard.service';
import { NotificationProfilesResolver } from '@shared/resolvers/notification-profiles.resolver';

export const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/routes').then((m) => m.routes),
  },
  {
    path: '',
    loadChildren: () => import('./modules/main/routes').then((m) => m.routes),
    canActivate: [AuthGuardService],
    resolve: {
      profilesLoaded: NotificationProfilesResolver,
    },
  },
  {
    path: '**',
    redirectTo: '',
  },
];
